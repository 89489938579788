import React from "react";
import { Box, Paper, Typography } from "@material-ui/core";
import DisplayPDF from "../../../components/displayPDF";
import { RedirectURL } from "../../Nosotros/utils";

export default function Presentacion() {

	return (
		<Paper style={{ borderRadius: 20, padding: 30 }}>
			<Typography variant="h5" align="center" style={{ fontWeight: "bold" }}>Presentación</Typography>
			<Box display="flex" justifyContent="center" style={{padding:20}}>
				<div style={{ width: "fit-content" }}>
					<DisplayPDF url={PRESENTACION_PDF} scale={0.7} onClick={() => RedirectURL(PRESENTACION_PDF)} />
				</div>
			</Box>
		</Paper>
	);
}

const PRESENTACION_PDF =
  "https://storage.googleapis.com/mi-cyd/ComiteCuidado/Presentacion%20Comite%20de%20Cuidado%202025.pdf";