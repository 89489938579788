import React from "react";
import Carousel from "react-material-ui-carousel";
import { Button, Backdrop, Fade, Modal, Paper, Box } from "@material-ui/core";
import {
  createTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  acordion_: {
    textAlign: "left",
    marginBottom: "1rem",
    paddingLeft: "3rem",
    borderRadius: "20px",
    // padding: theme.spacing(2, 4, 3),
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 4,
    color: "#fff",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },

  modalStyle1: {
    position: "absolute",
    // float: ,
    // top:'50%',
    // left:'50%',
    overflow: "scroll",
    height: "auto",
    width: "auto",
    // display: "block",
    margin: "auto",
    // cursor: "grab",
  },

  logrosypremios: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    height: "auto",
  },

  gridList: {
    width: "100%",
    height: "100%",
  },
}));

function Logros(props) {
  const classes = useStyles();
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalPpt, setOpenModalPpt] = React.useState(false);
  const [pptRuta, setPptRuta] = React.useState(null);
  var items = [
    {
      imagen:
        "https://storage.googleapis.com/mi-cyd/nosotros/Premios_reconocimientos_logros/Diploma%20Reconocimiento%20CyD%20Ingenieria_2025.png",
    },
    {
      imagen:
        "https://storage.googleapis.com/mi-cyd/nosotros/Premios_reconocimientos_logros/reconocimiento_2025.png",
    },
    {
      imagen:
        "https://storage.googleapis.com/mi-cyd/nosotros/Premios_reconocimientos_logros/Premio%206%20Estrellas%20CyD%202024.png",
    },
    {
      imagen:
        "https://storage.googleapis.com/mi-cyd/nosotros/Premios_reconocimientos_logros/Rec_5.jpg",
    },
    {
      imagen:
        "https://storage.googleapis.com/mi-cyd/nosotros/Premios_reconocimientos_logros/Rec_4.jpg",
    },
    {
      imagen:
        "https://storage.googleapis.com/mi-cyd/nosotros/Premios_reconocimientos_logros/Rec_3.png",
    },
    {
      imagen:
        "https://storage.googleapis.com/mi-cyd/nosotros/Premios_reconocimientos_logros/Rec_2.jpg",
    },
    {
      imagen:
        "https://storage.googleapis.com/mi-cyd/nosotros/Premios_reconocimientos_logros/Rec_1.jpg",
    },
    {
      imagen:
        "https://storage.googleapis.com/mi-cyd/nosotros/Premios_reconocimientos_logros/CUADRO%20DE%20HONOR%202022.png",
    },
    {
      imagen:
        "https://storage.googleapis.com/mi-cyd/nosotros/Premios_reconocimientos_logros/15.jpg",
    },
    {
      imagen:
        "https://storage.googleapis.com/mi-cyd/nosotros/Premios_reconocimientos_logros/19.jpg",
    },
    {
      imagen:
        "https://storage.googleapis.com/mi-cyd/nosotros/Premios_reconocimientos_logros/21.jpg",
    },
    {
      imagen:
        "https://storage.googleapis.com/mi-cyd/nosotros/Premios_reconocimientos_logros/22.jpg",
    },
    {
      imagen:
        "https://storage.googleapis.com/mi-cyd/nosotros/Premios_reconocimientos_logros/32.jpg",
    },
    {
      imagen:
        "https://storage.googleapis.com/mi-cyd/nosotros/Premios_reconocimientos_logros/41.jpg",
    },
    {
      imagen:
        "https://storage.googleapis.com/mi-cyd/nosotros/Premios_reconocimientos_logros/42.jpg",
    },
    {
      imagen:
        "https://storage.googleapis.com/mi-cyd/nosotros/Premios_reconocimientos_logros/52.jpg",
    },
  ];

  const handleCloseModal = () => {
    setOpenModalPpt(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModalPpt = () => {
    setOpenModalPpt(false);
  };

  const abrirImagen = (rutaImagen) => {
    setPptRuta(rutaImagen);
    setOpenModalPpt(true);
  };

  return (
    <React.Fragment>
      <Carousel
        autoPlay={false}
        indicators={false}
        navButtonsAlwaysVisible={true}
        animation="slide"
        fullHeightHover={true}
      >
        {items.map((item, i) => (
          <Box style={{ textAlign: "center" }}>
            <a href="#" onClick={() => abrirImagen(item.imagen)}>
              <img src={item.imagen} style={{ height: "480px" }}></img>
            </a>
          </Box>
        ))}
      </Carousel>
      <Modal
        align="center"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalStyle1}
        open={openModalPpt}
        // overflow="scroll"
        onClose={handleCloseModalPpt}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalPpt} onClick={handleCloseModal}>
          <Box
            // className={classes.paper}
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              // bgcolor:"lightgreen",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={pptRuta}
              style={{
                marginTop: "20px",
                height: "-webkit-fill-available",
                border: "groove",
                borderRadius: "2%",
              }}
              onClick={handleCloseModal}
            ></img>
          </Box>
        </Fade>
      </Modal>
    </React.Fragment>
  );
}

export default Logros;
